import * as React from 'react'
import style from './applications.css'
import { NetworkActionCard } from './network-action-card'
import { inject, observer } from 'mobx-react'
import { UiStore } from 'stores/ui-store'
import { DashboardLayout } from '../common/dashboard-layout'
import { AuthStore } from 'stores/auth-store'
import { applications, IApplication, ZendeskAppProfile } from '../../../constants/user/applications'
import { Loader } from 'components/loader'
import { AppStore } from 'stores/app-store'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { TrialExpiredOrTerminated } from 'modules/account-setup/trial-expired-or-terminated/trial-expired-or-terminated'
import { DashboardUsersStore } from '../user/user-store'
import { ModalLayerStore } from 'stores/modal-layer-store'
import { AddUserReturnValue } from 'interfaces/utils/add-user'
import { withTranslation, WithTranslation } from 'react-i18next'
import { Nda } from 'modules/account-setup/nda/nda'
import { supportEmails } from 'constants/support-emails'
import { ApplicationsStore } from './applications-store'
import { EditTableauModal } from './edit-tableau-modal'
import { showAlert } from 'utils/show-alert'
import { when } from 'mobx'
import { getCompanyAllowedApplications, isTerminated } from 'helpers/company-helpers'
import { mixpanelActions } from 'utils/mixpanelActions'
import { salesforcePortalProducts } from 'constants/salesforce.constants'
import { isPartnerSupportExperienceEnabled } from 'helpers/general-helpers'

const showEditTableauWelcomeModal = async (
  actionModalStore: ModalLayerStore,
  applicationsStore: ApplicationsStore,
  redirectUrl: string,
) => {
  return new Promise<AddUserReturnValue>(res => {
    const onClose = async (closeButton: AddUserReturnValue) => {
      applicationsStore.clearFormState()
      actionModalStore.removeModal()
      return res(closeButton)
    }

    actionModalStore.addModal(
      <EditTableauModal
        key={actionModalStore.getKey()}
        onClose={() => onClose(AddUserReturnValue.CloseButton)}
        applicationsStore={applicationsStore}
        redirectUrl={redirectUrl}
      />,
    )
  })
}

interface IDashboardApplicationsProps extends RouteComponentProps, WithTranslation {
  appStore: AppStore
  uiStore: UiStore
  authStore: AuthStore
  usersStore: DashboardUsersStore
  applicationsStore: ApplicationsStore
  actionModalStore?: ModalLayerStore
}

@inject(({ appStore }: { appStore: AppStore }) => ({
  appStore,
  uiStore: appStore.uiStore,
  authStore: appStore.authStore,
  usersStore: appStore.usersStore,
  actionModalStore: appStore.actionModalStore,
  homeStore: appStore.homeStore,
  applicationsStore: appStore.applicationsStore,
}))
@observer
class DashboardApplicationsComponent extends React.Component<IDashboardApplicationsProps> {
  store: ApplicationsStore

  constructor(props: IDashboardApplicationsProps) {
    super(props)
    this.props.authStore.update()

    this.store = new ApplicationsStore(this.props.appStore)

    when(
      () => !!this.props.authStore.currentUser && !!this.props.authStore.currentUser.company,
      () => {
        if (!this.props.authStore.currentUser.assignedApplications) {
          this.props.appStore.authStore.getUserAssignedApplications()
        }
      },
    )
  }

  componentDidMount() {
    const partnerId = this.props.appStore.authStore?.currentUser?.company?.partnerId

    mixpanelActions.track('Pageview - Applications', {
      'Page name': 'Applications',
      'Partner Id': partnerId,
    })
  }

  getSupportMailAddress = () => {
    const { currentUser } = this.props.authStore
    const { company } = currentUser

    const areAnyPortalProductsPurchased =
      company?.productsSummary?.purchased &&
      !!Object.keys(company?.productsSummary?.purchased).find(p =>
        salesforcePortalProducts.includes(p),
      )

    const supportEmail =
      areAnyPortalProductsPurchased || company.isChannelPartnerPortalCompany
        ? supportEmails.PARTNER_SUPPORT
        : company.ownerEmail

    return supportEmail
  }

  handleLaunchTableau = (redirectUrl: string, environment: string[]) => {
    if (environment.includes('development')) {
      return
    }
    return () => {
      const { t } = this.props
      const { data: homeData } = this.props.appStore.homeStore
      const { currentUser } = this.props.authStore
      const mailAddress = this.getSupportMailAddress()
      const hideTableauModal = currentUser.hideTableauWelcomeModal

      if (homeData.emptyHomes) {
        return showAlert({
          title: 'home.noHomesMsg',
          message: (
            <span>
              {t('home.noHomesDesc')} <a href={`mailto:${mailAddress}`}>{mailAddress}</a>
            </span>
          ),
          buttonText: 'btn.gotIt',
          isCloseShowing: true,
        })
      }

      if (!homeData.emptyHomes && !hideTableauModal) {
        return showEditTableauWelcomeModal(this.props.actionModalStore, this.store, redirectUrl)
      }

      return window.open(redirectUrl, '_blank')
    }
  }

  handleLaunchAppForAccountManager(app: IApplication) {
    const { t } = this.props
    const { assignedApplications, email } = this.props.authStore.currentUser
    if (!assignedApplications.find(a => a.id === app.id)) {
      showAlert({
        title: 'applications.accmgrNoAppAccessTitle',
        message: (
          <>{t('applications.accmgrNoAppAccessDescription', { appName: app.name, email })}</>
        ),
      })
    } else {
      window.open(app.url, '_blank')
    }
  }

  render() {
    const { currentUser } = this.props.authStore
    const { usersStore } = this.props.appStore

    if (!currentUser || !currentUser.company) {
      return <Loader />
    }

    if (!currentUser.company.signedNdaTimestamp) {
      return <Nda />
    }

    const { t } = this.props
    const { hasUserAccess } = this.props.appStore.accountSetupStore
    const hasAccess = hasUserAccess(window.location.pathname, currentUser)

    const { company, assignedApplications, activeProducts, applicationsAccessRights, isEmployee } =
      currentUser

    const isTrialExpiredOrTerminated = !isEmployee && isTerminated(company)

    const userApplications = isEmployee
      ? getCompanyAllowedApplications(currentUser.company).filter(appTile => {
          if (
            (isPartnerSupportExperienceEnabled && appTile?.sfToolName === 'Zendesk') ||
            (!isPartnerSupportExperienceEnabled && appTile?.sfToolName === 'Partner Support') ||
            appTile.id === process.env.OKTA_CLIENT_ID
          ) {
            return
          }
          return appTile
        })
      : assignedApplications
          ?.map(item => {
            const appTile = applications.find(app => {
              const isOldPanorama = item.id === '0oaay2baxsbzgrVh8357'
              const isPortal = item.id === process.env.OKTA_CLIENT_ID
              if (isOldPanorama || isPortal) {
                return
              }

              return (
                app.id === item.id &&
                (activeProducts.includes(app.sfProductName) ||
                  applicationsAccessRights.includes(app.sfToolName))
              )
            })

            const zendeskProdAppId: IApplication['id'] = '0oa2nw7gk36l57A4r357'
            if (
              appTile?.id === zendeskProdAppId &&
              !(item.appUserProfile as ZendeskAppProfile)?.organizationIds?.length
            ) {
              return
            }

            if (
              (isPartnerSupportExperienceEnabled && appTile?.sfToolName === 'Zendesk') ||
              (!isPartnerSupportExperienceEnabled && appTile?.sfToolName === 'Partner Support')
            ) {
              return
            }

            return appTile
          })
          .filter(appTile => !!appTile)

    const userApplicationsCount = userApplications?.filter(item => !!item).length

    const appTiles = userApplications?.map(appTile => {
      return (
        appTile && (
          <React.Fragment key={appTile.name}>
            <NetworkActionCard
              // id={appTile.id}
              title={appTile.name}
              description={appTile.description}
              extra={appTile?.extra}
              link={appTile.url}
              icon={appTile.icon}
              btnText={t('btn.launch')}
              currentUser={currentUser}
              loadingUsers={usersStore.isLoading}
              banner={appTile.banner ? t(appTile.banner) : undefined}
              partnerId={currentUser.company.partnerId}
              productStatus={company.productsInfo[appTile.sfProductName]}
              onClick={
                currentUser.isEmployee
                  ? () => this.handleLaunchAppForAccountManager(appTile)
                  : undefined
              }
            />
          </React.Fragment>
        )
      )
    })

    return hasAccess ? (
      <React.Fragment>
        {isTrialExpiredOrTerminated && <TrialExpiredOrTerminated />}
        <DashboardLayout currentUser={currentUser}>
          {assignedApplications === null ? (
            <Loader theme="small" rootClassName={style.loaderRoot} />
          ) : (
            <div className={style.root}>
              <div className={style.section}>
                <div className={style.header}>
                  <div className={style.headerTitle}>{t('applications.title')}</div>
                </div>
                {userApplicationsCount > 0 ? (
                  <div className={style.appTiles}>{appTiles}</div>
                ) : (
                  <div>
                    <div className={style.noAppsText}>{t('applications.noApplications')}</div>
                    <div className={style.noAppsTextDesc}>
                      {t('applications.noApplicationsDesc')}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </DashboardLayout>
      </React.Fragment>
    ) : (
      <Redirect
        to={{
          pathname: '/missing-access',
          state: { from: this.props.location },
        }}
      />
    )
  }
}

export const DashboardApplications = withTranslation()(DashboardApplicationsComponent)
