import * as React from 'react'
import { observer } from 'mobx-react-lite'
import { useStores } from 'utils/hooks/useStores'
import { Nda } from 'modules/account-setup/nda/nda'
import { DashboardLayout } from '../dashboard/common/dashboard-layout'
import { Loader } from 'components/loader'
import { NetworkActionCard } from '../dashboard/applications/network-action-card'
import {
  DOCEBO_TRAINING_URL,
  // GLOSSARY_URL,
  OPENSYNC_CPE_URL,
  PUBLIC_RESOURCES_URL,
} from '../../constants/dashboard/navigation'
import style from './knowledge-center.css'
import {
  SALESFORCE_SAML_API_DOCUMENTATION_URL,
  SALESFORCE_SAML_KNOWLEDGE_BASE_URL,
  ZENDESK_KNOWLEDGE_BASE_URL,
  ZENDESK_SAML_API_DOCUMENTATION_URL,
  ZENDESK_SAML_KNOWLEDGE_BASE_URL,
} from 'constants/applications/urls'
import { useTranslation } from 'react-i18next'
import { mixpanelActions } from 'utils/mixpanelActions'
import { GettingStarted } from 'modules/dashboard/getting-started/getting-started'
import { useGetUserAppAssignmentData } from 'utils/hooks/use-get-user-app-assignment-data'
import { getApplicationById } from 'constants/user/applications'
import { TrialExpiredOrTerminated } from 'modules/account-setup/trial-expired-or-terminated/trial-expired-or-terminated'
import { isTerminated } from 'helpers/company-helpers'
import { isPartnerSupportExperienceEnabled } from 'helpers/general-helpers'

export const KnowledgeCenter = observer(() => {
  const appStore = useStores()
  const { t } = useTranslation()
  useGetUserAppAssignmentData()
  const currentUser = appStore.authStore.currentUser

  React.useEffect(() => {
    mixpanelActions.track('Pageview - Knowledge Center', {
      'Page name': 'Knowledge Center',
      'Partner Id': currentUser?.company?.partnerId,
    })
  }, [currentUser])

  if (!currentUser || !currentUser.company) {
    return <Loader />
  }

  if (!currentUser.company.signedNdaTimestamp) {
    return <Nda />
  }

  const isZendeskAssigned = currentUser?.assignedApplications?.find(
    a => getApplicationById(a.id)?.name === 'Zendesk',
  )

  const knowledgeTiles = [
    {
      id: 'faq',
      name: t('knowledgeCenter.knowledgeBaseTitle'),
      description: t('knowledgeCenter.knowledgeBaseDesc'),
      url: isPartnerSupportExperienceEnabled
        ? SALESFORCE_SAML_KNOWLEDGE_BASE_URL
        : isZendeskAssigned
        ? ZENDESK_SAML_KNOWLEDGE_BASE_URL
        : ZENDESK_KNOWLEDGE_BASE_URL,
      icon: 'knowledge-base.svg',
    },
    {
      id: 'docebo',
      name: t('knowledgeCenter.learningCenterTitle'),
      description: t('knowledgeCenter.learningCenterDesc'),
      url: DOCEBO_TRAINING_URL,
      icon: 'plume-academy.svg',
    },
    {
      id: 'pubres',
      name: t('knowledgeCenter.pubResTitle'),
      description: t('knowledgeCenter.pubResDesc'),
      url: PUBLIC_RESOURCES_URL,
      icon: 'public-resources.svg',
    },
    // {
    //   id: 'glossary',
    //   name: t('knowledgeCenter.glossaryTitle'),
    //   description: t('knowledgeCenter.glossaryDesc'),
    //   url: GLOSSARY_URL,
    //   icon: 'plume-glossary.svg',
    // },
    {
      id: 'opensyncCpe',
      name: t('knowledgeCenter.opensyncCpeTitle'),
      description: t('knowledgeCenter.opensyncCpeDescription'),
      url: OPENSYNC_CPE_URL,
      icon: 'public-resources.svg',
    },
  ]

  if (isZendeskAssigned) {
    knowledgeTiles.push({
      id: 'apiDocumentation',
      name: t('knowledgeCenter.apiDocumentationTitle'),
      description: t('knowledgeCenter.apiDocumentationDescription'),
      url: isPartnerSupportExperienceEnabled
        ? SALESFORCE_SAML_API_DOCUMENTATION_URL
        : ZENDESK_SAML_API_DOCUMENTATION_URL,
      icon: 'knowledge-base.svg',
    })
  }

  const renderData = knowledgeTiles.map(knowledgeTile => {
    return (
      <React.Fragment key={knowledgeTile.name}>
        <NetworkActionCard
          btnText={t('btn.launch')}
          description={knowledgeTile.description}
          icon={knowledgeTile.icon}
          link={knowledgeTile.url}
          title={knowledgeTile.name}
          partnerId={currentUser.company.partnerId}
        />
      </React.Fragment>
    )
  })

  const isTrialExpiredOrTerminated = !currentUser.isEmployee && isTerminated(currentUser.company)

  return (
    <>
      {isTrialExpiredOrTerminated && <TrialExpiredOrTerminated />}
      <DashboardLayout currentUser={currentUser}>
        {currentUser?.assignedApplications === null ? (
          <Loader theme="small" rootClassName={style.loaderRoot} />
        ) : (
          <div className={style.root}>
            <div className={style.section}>
              <div className={style.header}>
                <div className={style.headerTitle}>{t('gettingStarted.title')}</div>
              </div>
              <GettingStarted />
            </div>
            <div className={style.section}>
              <div className={style.header}>
                <div className={style.headerTitle}>{t('knowledgeCenter.title')}</div>
              </div>
              <div className={style.knowledgeTiles}>{renderData}</div>
            </div>
          </div>
        )}
      </DashboardLayout>
    </>
  )
})
