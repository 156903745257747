import * as React from 'react'
import style from './account-info.css'
import { AccordionItem, Divider, Table } from 'plume-ui'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useStores } from 'utils/hooks/useStores'
import { CloudStatus } from '../common/dashboard-layout/cloud-status'
import { AccountStatus } from '../common/dashboard-layout/account-status'
import { PLUME_SUPPORT_PROCEDURE } from '../account/constants'
import { mixpanelActions } from 'utils/mixpanelActions'
import { CUSTOMER_SUCCESS_JIRA } from 'constants/applications/urls'
import { AccordionItemStyles } from 'plume-ui/dist/components/Accordion/AccordionItem'
import {
  convertOptionsArrayToRecord,
  isDateAfterNow,
  isPartnerSupportExperienceEnabled,
} from 'helpers/general-helpers'

type ProductTableRow = {
  name: string
  isPurchased: string
  trialEndDate: string
}

const productsSortingOrder = [
  'Haystack',
  'Frontline',
  'Panorama',
  'Signal',
  'HomePass',
  'Adapt',
  'Command',
  'Control',
  'Guard',
  'Sense',
  'WorkPass',
  'Concierge',
  'Flow',
  'Keycard',
  'Link',
  'Shield',
  'Harvest',
  'Clarity',
  'Generate',
  'Nurture',
  'Uprise',
  'Harmony',
  'Hub',
  'Inspect',
  'Places',
  'Flex',
  'Aegis',
  'Prioritize',
  'Provision',
  'Supervise',
]

const salesforceProductsToSkipOver = convertOptionsArrayToRecord<string>([
  'Flex Beta',
  'Plume Central',
  'Plume Central Beta',
  'Plume Central Dogfood',
  'Plume Central Opensync',
  'Plume Central Gamma',
  'Plume Central Kappa',
  'Plume NOC',
  'Plume NOC 2.0',
  'Plume NOC Beta',
  'Plume NOC Dogfood',
  'Plume NOC Opensync',
  'Plume NOC Gamma',
  'Plume NOC Kappa',
  'Plume NOC Legacy',
  'Plume Signal',
  'Plume Signal Beta',
  'Tableau',
  'QuerySight',
])

if (!isPartnerSupportExperienceEnabled) {
  salesforceProductsToSkipOver['Partner Support'] = true
}

const toolsToProductsRecord: Record<string, string> = {
  'Plume Central': 'Frontline',
  'Plume Central Beta': 'Frontline',
  'Plume Central Dogfood': 'Frontline',
  'Plume Central Opensync': 'Frontline',
  'Plume NOC': 'Frontline',
  'Plume NOC Beta': 'Frontline',
  'Plume NOC Dogfood': 'Frontline',
  'Plume NOC Opensync': 'Frontline',
  'Flex Beta': 'Flex',
  'Plume Signal': 'Signal',
  'Plume Signal Beta': 'Signal',
  Tableau: 'Panorama',
}

export const AccountInfo = () => {
  const { t } = useTranslation()
  const appStore = useStores()
  const [isProductsOpen, setIsProductsOpen] = React.useState(false)
  const { authStore } = appStore
  const { currentUser } = authStore
  const { company } = currentUser
  const { csmData } = company

  const isBillingContactEmpty =
    !company.billingContactName && !company.billingContactEmail && !company.billingContactPhone

  const isShippingAddressEmpty =
    !company.shippingAddress &&
    !company.shippingCity &&
    !company.shippingStateRegion &&
    !company.shippingZip &&
    !company.shippingCountry

  const isBillingAddressEmpty =
    (!company.billingAddress || company.billingAddress === 'none provided') &&
    (!company.billingCity || company.billingCity === 'none provided') &&
    (!company.billingStateRegion || company.billingStateRegion === 'none provided') &&
    (!company.billingZip || company.billingZip === 'none provided') &&
    (!company.billingCountry || company.billingCountry === 'none provided')

  const tableRows: ProductTableRow[] = []
  const productsKeysRemaining = convertOptionsArrayToRecord(Object.keys(company.productsInfo))

  for (const p of productsSortingOrder) {
    if (company.productsInfo[p]) {
      tableRows.push({
        name: p,
        isPurchased: company.productsInfo[p].isPurchased ? '1' : '',
        trialEndDate: company.productsInfo[p].trialEndDate || '',
      })
      delete productsKeysRemaining[p]
    }
  }
  for (const p in productsKeysRemaining) {
    if (!salesforceProductsToSkipOver[p]) {
      tableRows.push({
        name: p,
        isPurchased: company.productsInfo[p].isPurchased ? '1' : '',
        trialEndDate: company.productsInfo[p].trialEndDate || '',
      })
    } else if (
      toolsToProductsRecord[p] &&
      !tableRows.find(tr => tr.name === toolsToProductsRecord[p])
    ) {
      tableRows.push({
        name: toolsToProductsRecord[p],
        isPurchased: company.productsInfo[p].isPurchased ? '1' : '',
        trialEndDate: company.productsInfo[p].trialEndDate || '',
      })
    }
  }

  const tableHeaders = [
    {
      fieldName: 'name',
      name: t('common.product'),
      render: (row: ProductTableRow) => {
        const isSuite =
          (company?.productsSummary?.purchased?.[row.name] &&
            !!company?.productsSummary?.purchased?.[row.name]?.products) ||
          (company?.productsSummary?.trialing?.[row.name] &&
            !!company?.productsSummary?.trialing?.[row.name]?.products)
        return <span className={isSuite ? style.bold : undefined}>{row.name}</span>
      },
    },
    {
      fieldName: 'isPurchased',
      name: t('common.status'),
      render: (row: ProductTableRow) =>
        row.isPurchased
          ? t('common.active')
          : isDateAfterNow(row.trialEndDate)
          ? t('home.inTrial')
          : t('home.trialExpired'),
    },
    {
      fieldName: 'trialEndDate',
      name: t('home.trialEndDate'),
      render: (row: ProductTableRow) =>
        row.trialEndDate ? new Date(row.trialEndDate).toLocaleDateString() : '',
    },
  ]

  const launchEmail = (email: string) => {
    const a = document.createElement('a')
    a.href = `mailto:${email}`
    a.click()
  }

  const handleRequestMeetingClick = () => {
    const partnerId = company?.partnerId
    mixpanelActions.track('Navigation - Request Meeting', {
      'Partner Id': partnerId,
    })

    window.open(CUSTOMER_SUCCESS_JIRA, '_blank')
  }

  const renderBillingContact = () => (
    <div>
      <div className={style.text}>
        {company.billingContactName ? company.billingContactName : t('account.nameNotEntered')}
      </div>
      {company.billingContactEmail ? (
        <div
          className={classNames(style.text, style.emailLink)}
          onClick={() => launchEmail(company.billingContactEmail)}
        >
          {company.billingContactEmail}
        </div>
      ) : (
        <div className={style.text}>{t('account.emailNotEntered')}</div>
      )}
      <div className={style.text}>
        {company.billingContactPhone
          ? company.billingContactPhone
          : t('account.phoneCodeNotEntered')}
      </div>
    </div>
  )

  const renderShippingAddress = () => (
    <div>
      <div className={style.text}>
        {company.shippingAddress ? company.shippingAddress : t('account.addressNotEntered')}
      </div>
      <div className={style.text}>
        {company.shippingCity ? company.shippingCity : t('account.cityNotEntered')}
      </div>
      <div className={style.text}>
        {company.shippingStateRegion ? company.shippingStateRegion : t('account.stateNotEntered')}
      </div>
      <div className={style.text}>
        {company.shippingCountry ? company.shippingCountry : t('account.countryNotEntered')}
      </div>
      <div className={style.text}>
        {company.shippingZip ? company.shippingZip : t('account.postalCodeNotEntered')}
      </div>
    </div>
  )

  const renderBillingAddress = () => (
    <div>
      <div className={style.text}>
        {!!company.billingAddress && company.billingAddress !== 'none provided'
          ? company.billingAddress
          : t('account.addressNotEntered')}
      </div>
      <div className={style.text}>
        {!!company.billingCity && company.billingCity !== 'none provided'
          ? company.billingCity
          : t('account.cityNotEntered')}
      </div>
      <div className={style.text}>
        {!!company.billingStateRegion && company.billingStateRegion !== 'none provided'
          ? company.billingStateRegion
          : t('account.stateNotEntered')}
      </div>
      <div className={style.text}>
        {!!company.billingCountry && company.billingCountry !== 'none provided'
          ? company.billingCountry
          : t('account.countryNotEntered')}
      </div>
      <div className={style.text}>
        {!!company.billingZip && company.billingZip !== 'none provided'
          ? company.billingZip
          : t('account.postalCodeNotEntered')}
      </div>
    </div>
  )

  return (
    <div className={style.root}>
      <div className={style.container}>
        <div className={style.userProfileContainer}>
          {currentUser?.initials && (
            <div className={style.avatar}>
              <span>{currentUser?.initials}</span>
            </div>
          )}
          <div className={style.accountName}>
            {currentUser?.firstName} {currentUser?.lastName}
          </div>
          <AccountStatus centerAlign />
          <CloudStatus centerAlign />
        </div>
        <Divider orientation={'horizontal'} />
        <div className={style.heading}>{t('account.companyInfo')}</div>
        <div className={classNames(style.text, style.breakwrap)}>{company.name}</div>
        {company.portalNickname && (
          <div className={classNames(style.text, style.breakwrap)}>{company.portalNickname}</div>
        )}
        <div className={style.text}>
          {company.phone ? company.phone : t('account.phoneNotEntered')}
        </div>

        <div className={style.heading}>{t('account.partnerInfo')}</div>
        <div className={classNames(style.text, style.nowrap)}>{`${t('account.id')} ${
          company.partnerId
        }`}</div>
        {!company.profiles?.length && (
          <div className={style.onlyReportsPartnerId}>{t('account.onlyReportsPartnerId')}</div>
        )}
        {company.productsInfo && (
          <AccordionItem
            title={t('account.products')}
            isActive={isProductsOpen}
            onItemClick={() => setIsProductsOpen(!isProductsOpen)}
            classes={(curr: AccordionItemStyles) => ({
              ...curr,
              title: classNames(curr.title, style.override, style.accordionItemPadding),
            })}
          >
            <Table
              classes={curr => ({
                ...curr,
                cell: classNames(curr.cell, style.override, style.tableCellCustomization),
                dataRow: classNames(curr.dataRow, style.override, style.rowHover),
              })}
              dataRows={tableRows}
              headerRow={tableHeaders}
            />
          </AccordionItem>
        )}
      </div>

      <div className={classNames(style.container, style.flexContainer)}>
        <div className={style.innerContainer}>
          <div className={style.heading}>{t('account.accountManager')}</div>
          <div className={style.text}>{csmData.name}</div>
          <div
            className={classNames(style.text, style.emailLink)}
            onClick={handleRequestMeetingClick}
          >
            {t('nav.requestMeeting')}
          </div>

          <div className={style.heading}>{t('account.plumeSupport')}</div>
          <div className={style.text}>
            <a href={PLUME_SUPPORT_PROCEDURE} className={style.link} target="_blank">
              {t('account.plumeSupportLinkTitle')}
            </a>
          </div>

          <div className={style.heading}>{t('account.billingContact')}</div>
          {!isBillingContactEmpty ? (
            renderBillingContact()
          ) : (
            <div className={style.text}>{t('account.notEntered')}</div>
          )}
        </div>

        <div className={style.dividerContainer}>
          <Divider orientation={'vertical'} />
        </div>

        <div className={style.innerContainer}>
          <div className={style.heading}>{t('account.shippingAddress')}</div>
          {!isShippingAddressEmpty ? (
            renderShippingAddress()
          ) : (
            <div className={style.text}>{t('account.notEntered')}</div>
          )}

          <div className={style.heading}>{t('account.billingAddress')}</div>
          {!isBillingAddressEmpty ? (
            renderBillingAddress()
          ) : (
            <div className={style.text}>{t('account.notEntered')}</div>
          )}
        </div>
      </div>
    </div>
  )
}
