export const ZENDESK_KNOWLEDGE_BASE_URL = 'https://partnersupport.plume.com/hc/en-us'
export const CUSTOMER_SUCCESS_JIRA =
  'https://plumedesign.atlassian.net/servicedesk/customer/portal/24/group/56/create/249'

export const ZENDESK_SAML_KNOWLEDGE_BASE_URL =
  'https://external.sso.plume.com/app/plumeexternal_zendeskcustom_1/exk2nw7gk2xlWvoqg357/sso/saml?RelayState=https%3A%2F%2Fpartnersupport.plume.com%2Fhc%2Fen-us'

export const ZENDESK_SAML_RELEASE_NOTES_URL =
  'https://external.sso.plume.com/app/plumeexternal_zendeskcustom_1/exk2nw7gk2xlWvoqg357/sso/saml?RelayState=https%3A%2F%2Fpartnersupport.plume.com%2Fhc%2Fen-us%2Fcategories%2F10951308061207-Release-Notes'

export const ZENDESK_SAML_API_DOCUMENTATION_URL =
  'https://external.sso.plume.com/app/plumeexternal_zendeskcustom_1/exk2nw7gk2xlWvoqg357/sso/saml?RelayState=https%3A%2F%2Fpartnersupport.plume.com%2Fhc%2Fen-us%2Farticles%2F360046414573-Plume-Cloud-APIs'

export const ZENDESK_PARTNER_SUPPORT_TRANSITION_URL =
  'https://external.sso.plume.com/app/plumeexternal_zendeskcustom_1/exk2nw7gk2xlWvoqg357/sso/saml?RelayState=https%3A%2F%2Fpartnersupport.plume.com%2Fhc%2Fen-us%2Farticles%2F10951642578967-Plume-Portal-Release-Notes'

export const SALESFORCE_SAML_KNOWLEDGE_BASE_URL = `${process.env.PORTAL_API_BASE_URL}/misc/salesforce-digital-experience-sso/knowledge-base`
export const SALESFORCE_SAML_RELEASE_NOTES_URL = `${process.env.PORTAL_API_BASE_URL}/misc/salesforce-digital-experience-sso/release-notes`
export const SALESFORCE_SAML_API_DOCUMENTATION_URL = `${process.env.PORTAL_API_BASE_URL}/misc/salesforce-digital-experience-sso/api-documentation`
