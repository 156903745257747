import style from './getting-started.css'
import * as React from 'react'
import { Loader } from 'components/loader'
import { TrialExpiredOrTerminated } from 'modules/account-setup/trial-expired-or-terminated/trial-expired-or-terminated'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { useStores } from 'utils/hooks/useStores'
import { isTerminated } from 'helpers/company-helpers'
import { getApplicationById } from 'constants/user/applications'
import {
  SALESFORCE_SAML_RELEASE_NOTES_URL,
  ZENDESK_SAML_RELEASE_NOTES_URL,
} from 'constants/applications/urls'
import { mixpanelActions } from 'utils/mixpanelActions'
import { isPartnerSupportExperienceEnabled } from 'helpers/general-helpers'

export const GettingStarted = observer(() => {
  const appStore = useStores()
  const { t } = useTranslation()
  const currentUser = appStore.authStore.currentUser

  if (!currentUser) {
    return <Loader />
  }

  const isTrialExpiredOrTerminated = !currentUser?.isEmployee && isTerminated(currentUser?.company)

  const jobAidUrl = process.env.JOB_AID

  const isZendeskAssigned = currentUser?.assignedApplications?.find(
    a => getApplicationById(a.id)?.name === 'Zendesk',
  )

  const handleReleaseNotesLink = () => {
    const partnerId = currentUser?.company?.partnerId

    mixpanelActions.track('Account - Release notes link clicked', {
      'Partner Id': partnerId,
    })
  }

  const handleGettingStartedJobAidLink = () => {
    const partnerId = currentUser?.company?.partnerId

    mixpanelActions.track('Getting started - Job aid link clicked', {
      'Partner Id': partnerId,
    })
  }

  return (
    <>
      {isTrialExpiredOrTerminated && <TrialExpiredOrTerminated />}
      <div className={style.root}>
        <div className={style.infoCardsWrapper}>
          {jobAidUrl && (
            <div className={style.jobAidContainer}>
              <div className={style.jobAidTitle}>{t('gettingStarted.jobAidTitle')}</div>
              <div className={style.jobAidLink}>
                <a href={jobAidUrl} target="_blank" onClick={handleGettingStartedJobAidLink}>
                  {t('gettingStarted.jobAidText')}
                </a>
              </div>
            </div>
          )}
          {isZendeskAssigned && (
            <div className={style.jobAidContainer}>
              <div className={style.jobAidTitle}>{t('gettingStarted.releaseNotesTitle')}</div>
              <div className={style.jobAidLink}>
                <a
                  href={
                    isPartnerSupportExperienceEnabled
                      ? SALESFORCE_SAML_RELEASE_NOTES_URL
                      : ZENDESK_SAML_RELEASE_NOTES_URL
                  }
                  target="_blank"
                  onClick={handleReleaseNotesLink}
                >
                  {t('gettingStarted.releaseNotesDescription')}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
})
